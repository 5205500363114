import React from 'react';
import './Nav.css';
import './Header.css';
import { FiArrowLeft } from "react-icons/fi";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/actions';

function Header02() { 
  let history=useHistory();
  const dispatch = useDispatch();
  const logOut = async () => {
      try {
          dispatch(setUser(null));
          
          history.push('/');
      }
      catch (e) {
          console.log(e);
      }
    }

  return (
    <nav className="navbar" >
        <div className="navBar-text navbar-container">
            <Link to='/ListScreen'>
              <FiArrowLeft id="arrowLeft"/>
            </Link>
            <p className='txtHeader'>Regresar</p>
        </div>
      
    </nav>
  )
}

export default Header02;