import {SET_CURRENT_USER} from '../types/';

export const setCurrentUser = (
  payload = null,
) => {
  return {type: SET_CURRENT_USER, payload};
};

export const setUser = (payload = null) => (
  dispatch,
) => {
  dispatch(setCurrentUser(payload));
};