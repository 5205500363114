import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AlbaranPdfButton from "./AlbaranPdfButton";
import QRCode from "react-qr-code";
import "../components/QR.css";
import https from "../pluggins/http";
import { getAlbaran } from "../pluggins/albaranes";

function QR({ location }) {
  const [albaran, setAlbaran] = useState(null);

  useEffect(() => {
    const fetchAlbaran = async () => {
      try {
        const response = await getAlbaran(location.id);
        setAlbaran(response.data);
      } catch (error) {
        console.log("Error fetching albaran:", error);
      }
    };

    fetchAlbaran();
  }, [location.id]);

  const markAlbaran = async () => {
    try {
      const response = await https.post("updateAlbaran", {
        id: albaran.id,
        estado: "scanned",
      });
      if (response.data?.data) {
        alert("El albarán se actualizó correctamente.");
      }
    } catch {}
  };

  const generateQRContent = () => {
    if (!albaran) return "";
    console.log(albaran);
    const qrPayload = {
      operador: albaran.ver_obra.op.nombre,
      transportista: albaran.op.nombre,
      ref: albaran.ref,
      id: albaran?.id,
      fecha: albaran?.fecha,
      codigo_LER: albaran?.codigo_LER,
      m3_Kg: albaran?.m3_Kg,
      estado: albaran?.estado,
      comentarios: albaran?.comentarios,
      comentarios_vertedero: albaran?.comentarios_vertedero,
      conductor: albaran?.conductor,
      conductor_id: albaran?.conductor_id,
      camion: albaran?.camion,
      obra: albaran?.ver_obra.nombre,
      destino: albaran?.verts.nombre,
      destino_id: albaran?.verts.id,
      tipo_residuo: albaran?.residuo.nombre,
      peso: albaran?.peso,
      created_at: albaran?.created_at,
      updated_at: albaran?.updated_at,
      tipos: albaran?.tipos,
    };

    return qrPayload;
  };

  return (
    <div id="containerPrincipalQR">
      <div id="containerPopupQR">
        <p className="textQR">
          Tu Codigo QR ha sido {<br />} creado correctamente
        </p>
        <div className="containerTxt">
          <div className="containerQR">
            <span>
              <strong>Id: {albaran?.ref}</strong>
            </span>
          </div>
          <QRCode value={JSON.stringify(generateQRContent())} size={210} />
        </div>
        <div>
          <Link to={"/ListScreen"}>
            <button className="filterBTnQR">Volver</button>
          </Link>
          <button
            type="submit"
            onClick={markAlbaran}
            className="waitMarkButton"
          >
            Marcar "en espera"
          </button>
        </div>
      </div>
    </div>
  );
}

export default QR;
