/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import "./Confirmacion.css";
import { Link } from "react-router-dom";

function Confirmacion(props) {
  const frase =
    props?.location?.state?.frase ||
    new URLSearchParams(window.location.search).get("frase");
  console.log(frase);

  return (
    <div className="containerPrincipalConfirm">
      <div className="closeContainer">
        <img
          src={require("../assets/images/logo_white.png")}
          id="logoConfirm"
        />
        <Link to="/ListScreen">
          <FiX id="xCheck" />
        </Link>
      </div>

      <FiCheckCircle id="checkCircle" />
      <div>
        <p className="textConfirm">{frase}</p>
      </div>
    </div>
  );
}

export default Confirmacion;
