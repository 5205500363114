import {
  SET_ALBARANES,
  SET_CAMIONES,
  SET_OBRAS,
  SET_TIPOS,
  SET_VERTEDEROS,
  SET_ALBARAN_PENDIENTE,
} from "../types/app.types";

export const setAlbaranes = (payload = []) => ({
  type: SET_ALBARANES,
  payload,
});
export const setCamiones = (payload = []) => ({ type: SET_CAMIONES, payload });
export const setObras = (payload = []) => ({ type: SET_OBRAS, payload });
export const setTipos = (payload = []) => ({ type: SET_TIPOS, payload });
export const setVertederos = (payload = []) => ({
  type: SET_VERTEDEROS,
  payload,
});
export const setAlbaranPendiente = (payload = []) => ({
  type: SET_ALBARAN_PENDIENTE,
  payload,
});
