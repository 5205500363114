import React, { useState } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useForm } from "react-hook-form";
import ".././App.css";
import { Link, useHistory } from "react-router-dom";
import https from "../pluggins/http";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions";
import queryString from "query-string";
import { useSelector } from "react-redux";

function Login({ location }) {
  let history = useHistory();
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);
  const [hidePass, setHidePass] = useState(true);
  if (rol != null) {
    if (rol == "conductor") {
      history.push("/ListScreen");
    } else {
      const query = queryString.parse(location.search);
      if (query.id) {
        history.push("/Validation?id=" + query.id);
      } else if (query.condId) {
        history.push("/DetallesObra?idConductor=" + query.condId);
      } else {
        history.push("/ListScreen");
      }
    }
  }
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordShow = () => {
    setHidePass(!hidePass);
    console.log("clicked");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleLogin = async () => {
    try {
      if (email != "" && password != "") {
        const response = await https.post("auth/login", {
          email,
          password,
        });
        if (response?.data?.data) {
          dispatch(setUser(response?.data));
          console.log(response?.data?.rol);
          if (response?.data?.rol) {
            if (response?.data?.rol == "conductor") {
              history.push("/ListScreen");
            } else {
              const query = queryString.parse(location.search);
              if (query.id) {
                history.push("/Validation?id=" + query.id);
              } else if (query.condId) {
                history.push("/DetallesObra?idConductor=" + query.condId);
              } else {
                history.push("/ListScreen");
              }
            }
          }
        }
      } else {
        alert("Rellena los campos vacios");
      }
    } catch (e) {
      alert(e.response.data.message);
      console.log(e);
      console.log(e.response.data);
    }
  };
  return (
    <div className="App-container">
      <img src={require("../assets/images/logo.png")} className="logoLogin" />
      <div className="containerPopupLogin">
        <h1 className="bienvenidoLogin">BIENVENIDO</h1>
        <div className="IniciarTitulo">
          <div className="lineLogin" />
          <h3 className="iniciSesionLogin">Inicia sesión</h3>
          <div className="lineLogin" />
        </div>
        <form>
          <input
            placeholder="Correo"
            name="email"
            value={email}
            onChange={handleEmailChange}
            className="inputLogin"
          />
          <div className="password_field">
            <input
              placeholder="Contraseña"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              type={hidePass ? "password" : "text"}
              className="inputLogin"
            />
            <div onClick={handlePasswordShow} style={{ width: 0 }}>
              {hidePass ? (
                <FiEyeOff color="#596bb6" size={24} />
              ) : (
                <FiEye color="#596bb6" size={24} />
              )}
            </div>
          </div>
          <div>
            <input
              type="button"
              onClick={handleLogin}
              value="Iniciar Sesión"
              className="txtBtnInicia"
            />
          </div>
        </form>
        <div className="BtmContainer1">
          <div>
            <Link to="OlvidasteContrasena">
              <span className="olvidastePwd">¿Olvidaste tu Contraseña?</span>
            </Link>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
