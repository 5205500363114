import https from "./http";

const getVertederoId = async (idVertedero) => {
  const response = await https.post("contadores", {
    vert_id: idVertedero,
  });

  if (response?.data?.data) {
    return response?.data?.data;
  }
};

const getRef = async (idVertedero) => {
  let result = "";
  try {
    const data = await getVertederoId(idVertedero);
    if (("" + idVertedero).length < 4) {
      for (let i = 0; i < 4 - ("" + idVertedero).length; i++) {
        result += "0";
      }
    }
    result += idVertedero;
    result += "-";
    if (("" + data).length < 6) {
      for (let i = 0; i < 6 - ("" + data).length; i++) {
        result += "0";
      }
    }
    result += data;
  } catch (_error) {}
  return result;
};

export const crearAlbaran = async (payload) => {
  try {
    const ref = await getRef(payload?.destino);
    const response = await https.post("addAlbaran", {
      ...payload,
      ref: ref,
    });
    console.log(response);
    return response;
  } catch (_error) {
    throw _error;
  }
};

export const getAlbaran = async (id) => {
  try {
    const response = await https.get(`albaranes/${id}`);
    return response;
  } catch (_error) {
    throw _error;
  }
};

export const savePreferences = async (payload) => {
  try {
    const response = await https.post("preferences/save", {
      ...payload,
    });
    console.log(response);
    return response;
  } catch (_error) {
    throw _error;
  }
};
