import React from 'react';
import './Nav.css';
import './Header.css';
import { FiArrowLeft } from "react-icons/fi";
import { Link } from 'react-router-dom';

function Header() { 

  return (
    <nav className="navbar" >
        <div className="navBar-text navbar-container">
            <Link to='/ListScreen'>
              <FiArrowLeft id="arrowLeft"/>
            </Link>
            <p className='txtHeader'>Datos Albarán</p>
        </div>
        
    </nav>
  )
}

export default Header;