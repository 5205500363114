import React from 'react';

function Albaran(albaran){
  albaran = albaran.albaran.albaran;
  const numDI = albaran.operador_NIMA + "-" + albaran.fecha?.split("/")[2] + "-" + "0".repeat(7 - albaran.id?.toString().length) + albaran.id;
  
  const fechaAbaranCreateAt = new Date(albaran.created_at);
  const fechaTime = fechaAbaranCreateAt.getHours()+":"+fechaAbaranCreateAt.getMinutes();
  const fechaAlbaran = fechaAbaranCreateAt.getDay() +"/"+fechaAbaranCreateAt.getMonth() +"/"+fechaAbaranCreateAt.getFullYear();
  return (
    <div className="pdfContainer">
      <table>
        <tr>
          <td colSpan="100%" className="pdfPageHeader">DOCUMENTO DE IDENTIFICACION TRASLADO DE RESIDUOS DE CONSTRUCCION Y DEMOLICION</td>
        </tr>
        <tr>
          <td>
            <strong>ID ALBARAN: </strong>
          </td>
          <td>
            <strong>RESIDUO: </strong>
          </td>
          <td>
            <strong>CÓDIGO  LER: </strong>
          </td>
          <td>
            <strong>MATRÍCULA: </strong>
          </td>
          <td>
            <strong>FECHA <br/> ENTREGA: </strong>
          </td>
          <td>
            <strong>FECHA INICIO TRABAJO: </strong>
          </td>
          <td>
            <strong>CANTIDAD: </strong>
          </td>
          <td>
            <strong>PESO: </strong>
          </td>

        </tr>
        <tr>
          <td>{albaran.ref || ' -'}</td>
          <td>{albaran.tipo_residuo || ' -'}</td>
          <td>{albaran.codigo_LER || ' -'}</td>
          <td>{albaran.camion || ' -'}</td>
          <td>{albaran.hora_vertido || ' -'}</td>
          <td>{fechaAlbaran+"\n"+fechaTime || ' -'}</td>
          <td>{albaran.m3_Kg || ' -'}</td>
          <td>{albaran.peso + ' kg' || ' -'}</td>
        </tr>

        <tr>
          <td colSpan="100%" className="pdfPageTitle">OPERADOR</td>
        </tr>
        <tr>
          <td>
            <strong>RAZ  SOCIAL: </strong>
          </td>
          <td>
            <strong>TIPO  OPERADOR: </strong>
          </td>
          <td>
            <strong>NIF/CIF: </strong>
          </td>
          <td>
            <strong>DIRECCIÓN: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.empresa || ' -'}</td>
          <td>{albaran.operador_tipo || ' -'}</td>
          <td>{albaran.operador_cif || ' -'}</td>
          <td>{albaran.operador_dir || ' -'}</td>
        </tr>
        <tr>
          <td colSpan="100%" className="pdfPageTitle">CENTRO PRODUCTOR</td>
        </tr>
        <tr>
          <td>
            <strong>DENOMINACIÓN  OBRA: </strong>
          </td>
          <td>
            <strong>DIRECCIÓN OBRA: </strong>
          </td>
          <td>
            <strong>LICENCIA OBRA: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.obra || ' -'}</td>
          <td>{albaran.obra_dir || ' -'}</td>
          <td>{albaran.obra_licencia || ' -'}</td>
        </tr>
        <tr>
          <td colSpan="100%" className="pdfPageTitle">POSEEDOR</td>
        </tr>
        <tr>
          <td>
            <strong>NOMBRE/RAZ SOCIAL: </strong>
          </td>
          <td>
            <strong>NIF/CIF: </strong>
          </td>
          <td>
            <strong>NIMA: </strong>
          </td>
          <td>
            <strong>DIRECCIÓN: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.pos_nombre || ' -'}</td>
          <td>{albaran.pos_cif || ' -'}</td>
          <td>{albaran.pos_NIMA || ' -'}</td>
          <td>{albaran.pos_direccion || ' -'}</td>
        </tr>
        <tr>
          <td colSpan="100%" className="pdfPageTitle">TRANSPORTISTA</td>
        </tr>
        <tr>
          <td>
            <strong>RAZ SOCIAL/NOMBRE: </strong>
          </td>
          <td>
            <strong>NIF/CIF: </strong>
          </td>
          <td>
            <strong>DIRECCIÓN: </strong>
          </td>
          <td>
            <strong>NUM AUTORIZACIÓN/NUM REGISTRO: </strong>
          </td>
          <td>
            <strong>NIMA: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.empresa || ' -'}</td>
          <td>{albaran.operador_cif || ' -'}</td>
          <td>{albaran.operador_dir || ' -'}</td>
        {/* Pendientes: */}
          <td>{albaran.operador_num_auth || ' -'}</td> 
          <td>{albaran.operador_NIMA || ' -'}</td>
        </tr>
        <tr>
          <td colSpan="100%" className="pdfPageTitle">DESTINO</td>
        </tr>
        <tr>
          <td>
            <strong>DENOMINACIÓN  CENTRO GESTOR: </strong>
          </td>
          <td>
            <strong>RAZ SOCIAL/NOMBRE GESTOR: </strong>
          </td>
          <td>
            <strong>NIF/CIF: </strong>
          </td>
          <td>
            <strong>DIRECCIÓN: </strong>
          </td>
          <td>
            <strong>NUM AUTORIZACIÓN GESTOR: </strong>
          </td>
          <td>
            <strong>NIMA: </strong>
          </td>
          <td>
            <strong>OPERACIÓN DE TRATAMIENTO: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.vert_centro_gestor || ' -'}</td>
          <td>{albaran.destino || ' -'}</td>
          <td>{albaran.vert_cif || ' -'}</td>
          <td>{albaran.vert_dir || ' -'}</td>
          <td>{albaran.vert_num_gestor || ' -'}</td>
          <td>{albaran.vert_NIMA || ' -'}</td>
          <td>{albaran.operacion_tratamiento|| albaran.vert_operacion_tratamiento || ' -'}</td>
        </tr>
        <tr>
          <td colSpan="100%" className="pdfPageFooter"> </td>
        </tr>
        <tr>
          <td>
            <strong>COMENTARIOS TRANSPORTISTA: </strong>
          </td>
          <td>
            <strong>COMENTARIOS GESTOR: </strong>
          </td>
          <td>
            <strong>ACEPTACION: </strong>
          </td>
          <td>
            <strong>VALIDADO DIGITALMENTE POR: </strong>
          </td>
        </tr>
        <tr>
          <td>{albaran.comentarios || ' -'}</td>
          <td>{albaran.comentarios_vertedero || ' -'}</td>
          <td>{albaran.estado || ' -'}</td>
          <td>{albaran.destino || ' -'}</td>
        </tr>
      </table>
      <div className='numDi'>Número de DI: {numDI}</div>
    </div>
  )
}

export default Albaran;