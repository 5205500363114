import React from "react";
import LogoHeader from "./LogoHeader";
import AlbaranPdfButton from "./AlbaranPdfButton";
import { AiOutlineQrcode } from "react-icons/ai";
import "../components/AlbaranCreated.css";
import { Link, useHistory } from "react-router-dom";
import Header02 from "./Header02";
import https from "../pluggins/http";
import { Online } from "react-detect-offline";

function AlbaranCreated(props) {
  let history = useHistory();
  const estado = props.location;
  if (sessionStorage.length <= 0) {
    sessionStorage.setItem("albaran", JSON.stringify(props.location.albaran));
  }
  const albaran = JSON.parse(sessionStorage.getItem("albaran"));

  var isActive = false;

  const delAlbaran = async () => {
    try {
      const response = await https.post("delAlbaran", {
        id: albaran.id,
      });
      if (response.data?.data) {
        alert("El albarán se eliminó correctamente.");
        history.push("/ListScreen");
      }
    } catch {
      alert(
        "Parece que ha habido algún problema. Inténtalo de nuevo en unos segundos."
      );
    }
  };

  const estadoImage = (estado) => {
    if (estado === "active") {
      isActive = true;
      return (
        <img
          className="estadoImage"
          src={require("../assets/images/play.png")}
        />
      );
    } else if (estado === "approved") {
      return (
        <img
          className="estadoImage"
          src={require("../assets/images/tick.png")}
        />
      );
    } else if (estado === "denied") {
      return (
        <img
          className="estadoImage"
          src={require("../assets/images/xv3.png")}
        />
      );
    }
  };

  const fecha = new Date(albaran.created_at);
  const fechaNueva = fecha.toLocaleTimeString("es-ES");

  return (
    <div>
      <div>{estado === "Done" ? <LogoHeader /> : <Header02 />}</div>
      <div className="containerAlbaran">
        <div className="subContainerWhiteAlbaran">
          <div className="subContainerAlbaran spaceBetwwenAlbaran">
            <div className="spacingAlbaran">
              <p>
                <strong>
                  {albaran.ref} - {estadoImage(albaran.estado)}
                </strong>
              </p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer">
            <div className="spacingAlbaran">
              <p>
                <strong>Empresa Operadora</strong>
              </p>
              <p>{albaran.ver_obra.op.nombre}</p>
            </div>
            <div className="spacingAlbaran">
              <p>
                <strong>Empresa Transportista</strong>
              </p>
              <p>{albaran.op.nombre}</p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer">
            <div className="spacingAlbaran">
              <p>
                <strong>Obra</strong>
              </p>
              <p>{albaran.ver_obra.nombre}</p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer">
            <div className="spacingAlbaran">
              <p>
                <strong>Destino</strong>
              </p>
              <p>{albaran.verts.nombre}</p>
            </div>
            <div>
              <p>
                <strong>Tipo de Residuo</strong>
              </p>
              <p>
                {albaran.residuo.nombre} - CÓDIGO LER:{" "}
                {albaran.residuo.codigo_ler}
              </p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer flexAlbaran">
            <div className="spacingAlbaran">
              <p>
                <strong>
                  M<sup>3</sup>
                </strong>
              </p>
              <p>
                {albaran.m3_Kg} m<sup>3</sup>
              </p>
            </div>
            {albaran.peso && (
              <div className="spacingAlbaran">
                <p>
                  <strong>Peso</strong>
                </p>
                <p>{albaran.peso} kg</p>
              </div>
            )}
            <div className="spacingAlbaran">
              <p>
                <strong>Fecha</strong>
              </p>
              <p>{albaran.fecha}</p>
            </div>
            <div className="spacingAlbaran">
              <p>
                <strong>Hora</strong>
              </p>
              <p>{fechaNueva}</p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer flexAlbaran">
            <div className="spacingAlbaran">
              <p>
                <strong>Fecha de validación</strong>
              </p>
              <p>{albaran.hora_vertido}</p>
            </div>
          </div>
          <div className="subContainerAlbaran borderBottomContainer">
            <div className="spacingAlbaran">
              <p>
                <strong className="txtBoldAlbaran">Conductor</strong>
              </p>
              <p>{albaran.get_conductor.nombre}</p>
            </div>
            <div>
              <p>
                <strong>Matricula</strong>
              </p>
              <p>{albaran.camion}</p>
            </div>
          </div>
          <div className="subContainerAlbaran">
            <div className="spacingAlbaran">
              <p>
                <strong>Comentarios</strong>
              </p>
              <p>{albaran.comentarios}</p>
            </div>
          </div>
          <div className="subContainerAlbaran">
            <div className="spacingAlbaran">
              <p>
                <strong>Comentarios de Vertedero</strong>
              </p>
              <p>{albaran.comentarios_vertedero}</p>
            </div>
          </div>
          {albaran.estado === "active" || albaran.estado === "scanned" ? (
            <>
              <div className="subContainerAlbaran spaceArroundAlbaran">
                <div className="btnAlbaranContainer">
                  <AlbaranPdfButton albaran={albaran} />
                  <Link
                    to={{
                      pathname: "/QR",
                      id: albaran.id,
                    }}
                  >
                    <button type="submit" className="btnAlbaran BtnQRAlbaran">
                      <AiOutlineQrcode id="qrCodeAlbaran" />
                      Generar QR
                    </button>
                  </Link>
                </div>
              </div>
              <Online>
                <div className="subContainerAlbaran spaceArroundAlbaran">
                  <div>
                    <p onClick={delAlbaran} className="btnEliminarAlbaran">
                      Eliminar
                    </p>
                  </div>
                </div>
              </Online>
            </>
          ) : (
            <div className="subContainerAlbaran spaceArroundAlbaran">
              <div className="btnAlbaranContainer">
                <AlbaranPdfButton albaran={albaran} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AlbaranCreated;
