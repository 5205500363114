import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "main-root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
const Persistor = persistStore(store);
export { Persistor };
export default store;
