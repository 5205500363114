import {
  SET_ALBARANES,
  SET_CAMIONES,
  SET_OBRAS,
  SET_TIPOS,
  SET_VERTEDEROS,
  SET_ALBARAN_PENDIENTE,
} from "../types/app.types";

const initialState = {
  albaranes: [],
  camiones: [],
  obras: [],
  tipos: [],
  vertederos: [],
  albaranPendiente: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALBARANES: {
      return {
        ...state,
        albaranes: Array.isArray(action?.payload) ? action.payload : [],
      };
    }
    case SET_CAMIONES: {
      return {
        ...state,
        camiones: Array.isArray(action?.payload) ? action.payload : [],
      };
    }
    case SET_OBRAS: {
      return {
        ...state,
        obras: Array.isArray(action?.payload) ? action.payload : [],
      };
    }
    case SET_TIPOS: {
      return {
        ...state,
        tipos: Array.isArray(action?.payload) ? action.payload : [],
      };
    }
    case SET_VERTEDEROS: {
      return {
        ...state,
        vertederos: Array.isArray(action?.payload) ? action.payload : [],
      };
    }
    case SET_ALBARAN_PENDIENTE: {
      return {
        ...state,
        albaranPendiente: action?.payload,
      };
    }
    default:
      return state;
  }
};
