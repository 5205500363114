import React, { useState } from "react";
import '.././components/Mail.css';
import { Link, useHistory } from 'react-router-dom';
import https from '../pluggins/http';
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { setUser } from '../redux/actions';

function Mail( { location } ) {
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const query = queryString.parse(location.search);
    console.log(query);
    const emailFrom = query.email;
    const dispatch = useDispatch();
    let history=useHistory();
    const newPwd = async () => {
        console.log(emailFrom)
        try {
            if(pass != "" && confirmPass != ""){
                if (pass === confirmPass) {
                    const response = await https.post('auth/recoverPass', {
                        email: emailFrom,
                        pass,
                    })
                    if(response?.data?.message){
                        alert('Contraseña nueva establecida.');
                        history.push("/");
                    }
                    else {
                        alert('Error, la contraseña no se pudo cambiar.');
                    }
                }
                else {
                    alert('Error, las contraseñas no coinciden.');
                }
                    
            }else{
                alert("Campos vacíos, rellene todos los campos.");
            }
        }catch (e) {
                    alert(e.response.data.message)
                    console.log(e)
                    console.log(e.response.data)
      }};

      const handlePassChange = (e) => {
        setPass(e.target.value);
      };

      const handleConfirmPassChange = (e) => {
        setConfirmPass(e.target.value);
      };

    return(
        <div>
            <div id="containerPrincipalMail">
                <div id="containerPopupMail">
                    <img src={require('../assets/images/logo.png')} id="logoMail"/>
                    <p className="txtPass">Nueva contraseña</p>
                    <p className="txtPass02">Confirme nueva contraseña</p>
                    <form>
                        <label/>
                        <input placeholder="Nueva contraseña" value={pass} onChange={handlePassChange} className="pass"/>
                        <label/>
                        <input placeholder="Confirme nueva contraseña" value={confirmPass} onChange={handleConfirmPassChange} className="confirmPass"/>
                    </form>
                <div className="containerBtnsMail">
                    <Link to="/">
                        <button className="txtBtnAtrasMail">Atrás</button>
                    </Link>
                    <button className="txtBtnEnviarMail" onClick={newPwd}>Enviar</button>    
                </div>
                </div>
            </div>
        </div>   
    );
}

export default Mail;