import https from "../pluggins/http";

export const fetchAlbaranes = async ({ id, rol, fecha } = {}) => {
  try {
    const response = await https.post("albaranes?today=true", {
      id,
      rol,
      fecha,
    });
    return Object.values(response?.data?.data);
  } catch (_error) {
    throw _error;
  }
};

export const fetchCamiones = async (id) => {
  try {
    const response = await https.post("camiones", { id });
    return Array.isArray(response?.data?.data) ? response?.data?.data : [];
  } catch (_error) {
    throw _error;
  }
};

export const fetchObras = async (operador) => {
  try {
    const response = await https.post("obras", { operador });
    return Array.isArray(response?.data?.data) ? response?.data?.data : [];
  } catch (_error) {
    throw _error;
  }
};

export const fetchTipos = async () => {
  try {
    const response = await https.get("tipos");
    return Array.isArray(response?.data?.data) ? response?.data?.data : [];
  } catch (_error) {
    throw _error;
  }
};

export const fetchVertederos = async (id) => {
  try {
    const response = await https.get("vertederos");
    return Array.isArray(response?.data?.data) ? response?.data?.data : [];
  } catch (_error) {
    throw _error;
  }
};

export const fetchPreferences = async (id) => {
  try {
    // console.log(id);
    const response = await https.get(`preferences/${id}`);
    console.log(response);
    return response;
  } catch (_error) {
    throw _error;
  }
};
