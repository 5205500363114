import React, { useState, useEffect } from "react";
import Header02 from "./Header02";
import "./Validation.css";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import https from "../pluggins/http";
import { useSelector } from "react-redux";
import moment from "moment";
import { ca } from "date-fns/locale";
import { isEditable } from "@testing-library/user-event/dist/utils";
import AlbaranPdfButton from "./AlbaranPdfButton";
import "../components/AlbaranCreated.css";

function Validation({ location }) {
  let history = useHistory();
  const user = useSelector((state) => state?.auth?.currentUser?.rol);
  const vert_id = useSelector((state) => state?.auth?.currentUser?.data?.id);
  const current = new moment();
  const day = current.format("D");
  const month = current.format("MM");
  const year = current.format("YYYY");
  const hours = current.format("HH");
  const minuts = current.format("mm");
  const [tipo_residuoSelected, settipo_residuoSelected] = useState("");
  const [tipo_residuoid, settipo_residuoid] = useState("");
  const [tipoResiduo, setTipoResiduo] = useState([]);
  const [isMounted, setisMounted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [codigo_LER_Selected, setCodigo_LER_Selected] = useState("");
  const [peso, setPeso] = useState("");
  var hora = moment("" + hours + minuts, "hmm").format("HH:mm");
  var fecha_formato = moment(
    day + "/" + month + "/" + year,
    "DD MM YYYY"
  ).format("DD/MM/YYYY");
  var hora = hours + ":" + minuts;
  var completDate = fecha_formato + " - " + hora;

  if (user !== "vertedero") {
    alert("Tu usuario no tiene permitido ver el contenido de esta página.");
    history.push("/ListScreen");
  }
  const query = queryString.parse(location.search);

  const [albaran, setAlbaranData] = useState([]);
  const [Coments, setComents] = useState([]);
  const setAlbaran = async () => {
    const response = await https.post("albaran", {
      id: query.id,
    });

    console.log("DATA", response?.data?.data);
    if (response?.data?.data?.verts?.id !== vert_id) {
      alert("Este albarán no corresponde a este vertedero");
      history.push("/ListScreen");
    }

    setAlbaranData(response?.data?.data);
    settipo_residuoSelected(response?.data?.data.residuo.nombre);
    setCodigo_LER_Selected(response?.data?.data.residuo.codigo_ler);
    setComents(response?.data?.data?.comentarios_vertedero);
  };

  const updateAlbaran = async (respuesta, completDate) => {
    console.log(respuesta);

    try {
      const response = await https.post("updateAlbaran", {
        id: albaran.id,
        estado: respuesta,
        comentario: Coments,
        hora_vertido: completDate,
        tipos: tipo_residuoid,
        peso: peso,
      });
      if (response.data?.data) {
        alert("El albarán se actualizó correctamente.");
        history.push("/ListScreen");
      }
    } catch {
      alert(
        "Parece que ha habido algún problema. Inténtalo de nuevo en unos segundos."
      );
    }
  };
  const getTiops = async () => {
    try {
      const response = await https.get("tipos");
      if (response?.data?.data) {
        setTipoResiduo(response?.data.data);
      }
    } catch {
      console.log("Error");
    }
  };

  const handleDenied = () => {
    updateAlbaran("denied", completDate);
  };
  const handleApproved = () => {
    updateAlbaran("approved", completDate);
  };

  useEffect(() => {
    setAlbaran();
    getTiops();
  }, []);
  console.log(tipoResiduo);

  const fecha = new Date(albaran.created_at);
  const fechaNueva = fecha.toLocaleTimeString("es-ES");
  const handleTiposchange = (e) => {
    settipo_residuoid(e.target.value);
    tipoResiduo.map((option) =>
      "" + option.id === "" + e.target.value
        ? (settipo_residuoSelected(option.nombre), console.log(option.nombre))
        : null
    );
  };

  return (
    <div>
      <div>
        <Header02 />
      </div>
      <div className="containerValidation">
        <div className="subContainerWhiteValidation">
          <div className="subContainer spaceBetwwenValidation">
            <div className="spacingValidation">
              <p>
                <strong>Validación de Información</strong>
              </p>
            </div>
            <div>
              <img src={require("../assets/images/pencil.png")}></img>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Empresa Operadora</strong>
              </p>
              <p>{albaran?.ver_obra?.op?.nombre}</p>
            </div>
            <div className="spacingValidation">
              <p>
                <strong>Empresa Transportista</strong>
              </p>
              <p>{albaran?.get_conductor?.op?.nombre}</p>
            </div>
            <div>
              <p>
                <strong>ID</strong>
              </p>
              <p>{albaran.ref}</p>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Obra</strong>
              </p>
              <p>{albaran?.ver_obra?.nombre}</p>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Destino</strong>
              </p>
              <p>{albaran.verts?.nombre}</p>
            </div>
            <div>
              <p>
                <strong>Tipo de Residuo</strong>
              </p>
              {query.route == "list" && albaran.estado != "activo" ? (
                <p>
                  {tipo_residuoSelected} - CÓDIGO LER: {codigo_LER_Selected}{" "}
                </p>
              ) : (
                <div className="tResiduo">
                  {" "}
                  <select
                    name="destinoName"
                    className="selectColor selectSizer"
                    disabled={!isEditable}
                    onChange={handleTiposchange}
                  >
                    {tipoResiduo.map((option, i) => (
                      <>
                        {option.nombre === tipo_residuoSelected ? (
                          <>
                            {!isMounted &&
                              (settipo_residuoid(option.id),
                              setisMounted(true))}
                            <option
                              value={option.id}
                              key={`option-${i}`}
                              selected="selected"
                            >
                              {option.nombre +
                                "- 	CÓDIGO LER: " +
                                option.codigo_ler}
                            </option>
                          </>
                        ) : (
                          <option value={option.id} key={`option-${i}`}>
                            {option.nombre +
                              "- 	CÓDIGO LER: " +
                              option.codigo_ler}
                          </option>
                        )}
                      </>
                    ))}
                  </select>{" "}
                  <button
                    className="mdfBtn"
                    onClick={() => setIsEditable(true)}
                  >
                    Modificar
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            {tipo_residuoSelected !== "Tierra" && (
              <div className="spacingValidation">
                <p>
                  <strong>Peso</strong>
                </p>
                <>
                  {query.route == "list" ? (
                    <p>{albaran.peso} Kg</p>
                  ) : (
                    <input
                      placeholder="Kg"
                      className="inputs peso"
                      onChange={(e) => setPeso(e.target.value)}
                    />
                  )}
                </>
              </div>
            )}
            <div className="spacingValidation">
              <p>
                <strong>
                  M<sup>3</sup>
                </strong>
              </p>
              <p>
                {albaran.m3_Kg} m<sup>3</sup>
              </p>
            </div>
            <div className="spacingValidation">
              <p>
                <strong>Fecha</strong>
              </p>
              <p>{albaran.fecha}</p>
            </div>
            <div className="spacingValidation">
              <p>
                <strong>Hora</strong>
              </p>
              <p>{fechaNueva}</p>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Fecha de validación</strong>
              </p>
              <p>{albaran.hora_vertido}</p>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Conductor</strong>
              </p>
              <p>{albaran?.get_conductor?.nombre}</p>
            </div>
            <div>
              <p>
                <strong>Matricula</strong>
              </p>
              <p>{albaran.camion}</p>
            </div>
          </div>
          <div className="subContainerValidation borderBottomContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Comentario del Conductor</strong>
              </p>
              <p>{albaran.comentarios}</p>
            </div>
          </div>
          <div className="subContainerValidation">
            <div className="spacingValidation">
              <p>
                <strong>Comentarios de Vertedero</strong>
              </p>
              {query.route !== "list" ? (
                <input
                  type="text"
                  value={Coments}
                  onChange={(e) => setComents(e.target.value)}
                  className="comentarioInputValidation"
                ></input>
              ) : (
                <p>{albaran.comentarios_vertedero}</p>
              )}
            </div>
          </div>
          {(albaran.estado === "active" || albaran.estado === "updated") &&
          query.route !== "list" ? (
            <div className="subContainerValidation spaceArroundValidation">
              <div className="btnsContainer">
                <button
                  type="submit"
                  onClick={handleDenied}
                  className="btnValidation BtnDangerValidation"
                >
                  Rechazar
                </button>
                <button
                  type="submit"
                  onClick={handleApproved}
                  className="btnValidation BtnSuccesValidation"
                >
                  Validar
                </button>
              </div>
            </div>
          ) : (
            <div className="subContainerAlbaran spaceArroundAlbaran">
              <div className="btnAlbaranContainer">
                <AlbaranPdfButton albaran={albaran} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Validation;
