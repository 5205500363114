import axios from "axios";

export const instance = axios.create({
  baseURL: "https://admin.icargoapp.com/public/api/",
  // baseURL: "http://127.0.0.1:8000/api",
});

instance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = "";
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";

    console.log(config);

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("error", error?.response);
    return Promise.reject(error);
  }
);

export default instance;
