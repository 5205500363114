import React, { useState } from "react";
import "./Nav.css";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions";
import { FiX } from "react-icons/fi";
import { FiMenu } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";

function LogoHeaer() {
  let history = useHistory();
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);
  const dispatch = useDispatch();
  const logOut = async () => {
    try {
      dispatch(setUser(null));
      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };
  const goPreferences = async () => {
    try {
      history.push("/preferences");
    } catch (e) {
      console.log(e);
    }
  };
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  const closeMenu = () => {
    setNavbarOpen(false);
  };
  const user = useSelector((state) => state?.auth?.currentUser?.data);
  // const urlXlsx = "https://admin.icargoapp.com/public/file-export?id=" + user.id;
  // const urlCsv = "https://admin.icargoapp.com/public/file-export-format?id=" + user.id;

  return (
    <nav className="navbar">
      <div className="navbar-container navBar-icon">
        <img
          className="img-logo"
          src={require("../assets/images/logo.png")}
        ></img>
      </div>
      <div className="HamburegerMenu">
        <button className="iconsBurger" onClick={handleToggle}>
          {navbarOpen ? (
            <FiX size={24} color="#596bb6" />
          ) : (
            <FiMenu size={24} color="#596bb6" />
          )}
        </button>
        <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
          <a>
            <li>ID: {user.id}</li>
          </a>

          {rol === "conductor" && (
            <a onClick={goPreferences}>
              <li>QR Vertido</li>
            </a>
          )}
          <a onClick={logOut}>
            <li>Cerrar Sesión</li>
          </a>
        </ul>
      </div>
    </nav>
  );
}

export default LogoHeaer;
