import React, { useState } from "react";
import '.././components/OlvidasteContrasena.css';
import { Link, useHistory } from 'react-router-dom';
import https from '../pluggins/http';
import { useDispatch } from "react-redux";
import { setUser } from '../redux/actions';

function OlvidasteContrasena() {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    let history=useHistory();
    const forgottenPwd = async () => {
        console.log(email
)
        try {
            if(email!=""){
                    const response = await https.post('auth/forgotPass', {
                        email,
                        // password,
                    })
                    if(response?.data.message){
                        alert(response.data.message);
                        history.push("/");
                    }
                    else {
                        alert("error");
                    }
            }else{
                alert("Campo vacío, introduzca un Email.");
            }
        }catch (e) {
                    alert(e.response.data.message)
                    console.log(e)
                    console.log(e.response.data)
      }};

      const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };

    return(
        <div id="containerPrincipalOC">
            <div id="containerPopupOC">
                <img src={require('../assets/images/logo.png')} id="logoOC"/>
              <p className="txtEmail">Introduce email</p>
                <form>
                    <label/>
                    <input placeholder="Email" value={email} onChange={handleEmailChange} className="email"/>
                </form>
            <div className="containerBtns">
                <Link to="/">
                    <button className="txtBtnAtras">Atrás</button>
                </Link>
           
                <button className="txtBtnEnviar" onClick={forgottenPwd}>Enviar</button>    
            </div>
            </div>
        </div>
                
    );
}

export default OlvidasteContrasena;