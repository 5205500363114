import React from "react";
import { renderToString } from "react-dom/server";
import Albaran from "./AlbaranTemplate";
import jsPDF from "jspdf";
import { AiOutlineFilePdf } from "react-icons/ai";

function AlbaranPdfButton(albaran) {
  let elementAsString = renderToString(<Albaran albaran={albaran} />);
  console.log(albaran.albaran.id);
  var doc = new jsPDF();
  const pdfDownload = () => {
    doc.html(elementAsString, {
      callback: function (doc) {
        doc.save("albaran.pdf");
      },
      html2canvas: {
        scale: 0.5,
      },
    });
  };
  return (
    <div>
      <button
        className="btnAlbaran BtnQRAlbaran"
        onClick={(e) => {
          e.preventDefault();
          window.location.href =
            "https://admin.icargoapp.com/public/downloadPDF/" +
            albaran.albaran.id;
        }}
      >
        <AiOutlineFilePdf id="qrCodeAlbaran" />
        D.I. PDF
      </button>
    </div>
  );
}

export default AlbaranPdfButton;
