import "./App.css";
// import Nav from "./components/Nav";
import ListScreen from "./components/ListScreen";
import Login from "./components/Login";
import Confirmacion from "./components/Confirmacion";
import DetallesObra from "./components/DetallesObra";
import QR from "./components/QR";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Validation from "./components/validation";
import OlvidasteContrasena from "./components/OlvidasteContrasena";
import PreferencesSave from "./components/PreferencesSave";
import albaranCreated from "./components/AlbaranCreated";
import Mail from "./components/Mail";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlbaranes, setAlbaranPendiente } from "./redux/actions";
import { Detector } from "react-detect-offline";
import { crearAlbaran } from "./pluggins/albaranes";
import { format } from "date-fns";
import { fetchAlbaranes } from "./services/albaranes";
function App() {
  const dispatch = useDispatch();
  const albaranPendiente = useSelector((state) => state?.app?.albaranPendiente);
  const user = useSelector((state) => state?.auth?.currentUser?.data);
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);

  const getAlbaranes = async () => {
    if (window.navigator.onLine) {
      let filtro = "";
      if (rol === "vertedero") {
        filtro = "" + format(new Date(), "dd/MM/yyyy");
      }
      const response = await fetchAlbaranes({
        id: user.id,
        rol: rol,
        fecha: filtro,
      });

      dispatch(setAlbaranes(response));
    }
  };

  const _processAlbaranPendiente = async (payload) => {
    try {
      const response = await crearAlbaran(payload);
      await getAlbaranes();
      if (response?.data?.data) {
        window.location.href = `/Confirmacion?frase=${encodeURIComponent(
          "Albarán de vertido creado correctamente"
        )}`;
      }
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/ListScreen" exact component={ListScreen} />
            <Route path="/Confirmacion" exact component={Confirmacion} />
            <Route path="/DetallesObra" exact component={DetallesObra} />
            <Route path="/QR" exact component={QR} />
            <Route path="/Validation" exact component={Validation} />
            <Route path="/preferences" exact component={PreferencesSave} />
            <Route
              path="/OlvidasteContrasena"
              exact
              component={OlvidasteContrasena}
            />
            <Route path="/albaranCreated" exact component={albaranCreated} />
            <Route path="/Mail" exact component={Mail} />
          </Switch>
        </div>
      </Router>
      <Detector
        render={() => null}
        onChange={(online) => {
          if (online && albaranPendiente && user) {
            _processAlbaranPendiente(albaranPendiente);
            dispatch(setAlbaranPendiente(null));
          }
        }}
      />
    </>
  );
}

export default App;
